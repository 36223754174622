import React, { useState, useEffect, useContext } from "react";
import HandleModal from "./Context/ModalContext";
import { HashLink } from "react-router-hash-link";
import ValueList from "./Values/ValueList";
import axios from "../apis/portfolioAPI";
import aboutBackground from "../assets/img/CoolGeometricBackground-3.jpg";
import { LoadingScreenContext } from "./Context/LoadingScreenContext";
import BackgroundImageOnLoad from "background-image-on-load";

// https://source.unsplash.com/random <-- Random image link
// https://images.unsplash.com/photo-1515462277126-2dd0c162007a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1870&q=80
// https://images.hdqwalls.com/download/splash-art-gradient-8k-ui-2560x1440.jpg <- Blue and black website background

export default function About() {
  const [aboutHero, setAboutHero] = useState("");
  const { loadingStatus, bgImageLoadingStatus } =
    useContext(LoadingScreenContext);

  const [isLoading, setIsLoading] = loadingStatus;
  const [bgImageIsLoading, setBgImageIsLoading] = bgImageLoadingStatus;

  const fetchAboutHero = async () => {
    setBgImageIsLoading(true);
    setIsLoading(true);
    const response = await axios.get("/heroes/about-hero/");
    setAboutHero(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAboutHero();
  }, []);

  return (
    <main>
      {/* TODO:Create hero component with props to change styling and elements */}
      <section
        id="aboutHero"
        className="about-hero"
        style={{
          backgroundImage: `url(${aboutBackground})`,
        }}
      >
        <BackgroundImageOnLoad
          src={aboutBackground}
          onLoadBg={() => setBgImageIsLoading(false)}
          onError={(err) => console.log("error", err)}
        />
        <div className="container vh-full flex flex-middle flex-center column text-white text-center relative">
          <h1 className="mb-1 pb-1">{aboutHero.name}</h1>
          <p className="about-subtitle w-50">{aboutHero.description}</p>
          {/* TODO: Add scroll icon hover effects */}
          <HashLink
            smooth
            to="#aboutValuesSection"
            className="about-scroll-link"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="50"
              height="70"
              viewBox="0 0 172 172"
              style={{ fill: "#000000" }}
            >
              <g style={{ mixBlendMode: "normal" }}>
                <path d="M0,172v-172h172v172z" fill="none"></path>
                <g fill="#ffffff">
                  <path d="M85.94625,3.38625c-1.89469,0.04031 -3.41312,1.59906 -3.38625,3.49375v148.79344l-28.58156,-27.42594c-0.65844,-0.65844 -1.55875,-1.02125 -2.48594,-1.00781c-1.3975,0.04031 -2.64719,0.91375 -3.14437,2.21719c-0.49719,1.30344 -0.16125,2.78156 0.87344,3.73562l36.77844,35.31375l36.77844,-35.31375c0.90031,-0.84656 1.27656,-2.10969 0.98094,-3.30562c-0.29563,-1.19594 -1.20938,-2.15 -2.39188,-2.48594c-1.19594,-0.34937 -2.4725,-0.02687 -3.34594,0.84656l-28.58156,27.42594v-148.79344c0.01344,-0.92719 -0.34937,-1.8275 -1.00781,-2.48594c-0.65844,-0.65844 -1.55875,-1.02125 -2.48594,-1.00781z"></path>
                </g>
              </g>
            </svg>
            <div className="scroll-link-text">Learn More</div>
          </HashLink>
        </div>
      </section>

      <section
        id="aboutValuesSection"
        className="about-info-section py-5 text-center"
      >
        <ValueList />
      </section>
      <section className="about-info-section text-center">
        <div>
          <div className="flex about-paragraph-section">
            <div className="w-50 my-auto about-info-layout">
              <h2 className="pb-1">Kenny Lepping</h2>
              <p className="pb-2 about-job-title">Full Stack Web Developer</p>
              <p className="about-description">
                Hi, I am Kenny. I primarily work as a full-stack developer on
                Python, Django, AWS, and React projects. I have developed with
                languages like C++, Swift, Ruby, and Java, but Python and
                JavaScript are my favorites. I have a passion for building
                eloquent and intuitive projects. I graduated magna cum laude
                with a Bachelor’s degree in Interactive Multimedia from The
                College of New Jersey. I specialized in full-stack web
                development and music production graduating with a 4.0 GPA in
                this major.
              </p>
              <HandleModal buttonType={"cta"} cssClass={"about-cta-button"} />
            </div>
            {/* <div className="about-description-img" /> */}
          </div>
        </div>
      </section>
    </main>
  );
}
