import React, { useContext, useEffect, useRef } from "react";
import anime from "animejs";
import ContactModal from "./ContactModal";
import HandleModal, { ModalContext } from "./Context/ModalContext";
import LoadingScreen, { LoadingScreenContext } from "./Context/LoadingScreenContext";
import Logo from "../assets/svg/K-logo.svg"; // TODO: Fix relative paths => ../
import {
  changeBodyOverflow,
  changeNavLinkDisplay,
  changeNavLinkDisplayNone,
  horizontalMenuStyling,
  mobileMenuMediaQuery,
  verticalMenuStyling,
} from "../anime_js/main";
import { HashLink } from "react-router-hash-link";
import { NavLink, useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export default function NavBar() {
  const history = useHistory();
  const { isOpened } = useContext(ModalContext);
//   const { setIsLoading } = useContext(LoadingScreenContext);
const { loadingStatus, bgImageLoadingStatus } =
    useContext(LoadingScreenContext);

  const [ isLoading, setIsLoading ] = loadingStatus;
  const [ bgImageIsLoading, setBgImageIsLoading ] = bgImageLoadingStatus;

  const checkBool = useRef();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1200px)" });
  const menuCheckboxButton = document.querySelector("#menu-checkbox");

  // Delays going to the link that was clicked
  function loadDelayAndGo(e, path) {
    e.preventDefault();
    console.log(path);
    if (window.location.pathname !== path) {
      setIsLoading(true);
      setBgImageIsLoading(true)
    }
    if (path === "/" && checkBool.current.checked === true) {
      setTimeout(() => {
        menuCheckboxButton.click();
      }, 200);
    }
    setTimeout(() => {
      history.push(path);
      if (path === "/#portfolio") {
        document.getElementById("portfolioList").click()
      }
    }, 500);
  }

  useEffect(() => {
    let navLinkTransitionOpacity = 0;

    if (isOpened || !isTabletOrMobile) {
      navLinkTransitionOpacity = 1;
    }

    const tl = anime.timeline({
      easing: "easeInSine",
      duration: 200,
      autoplay: false,
    });
    tl.add({
      targets: ".menu-block-one",
      height: [0, "100%"],
    })
      .add({
        targets: ".menu-block-two",
        height: [0, "100%"],
      })
      .add({
        targets: ".navbar-menu li",
        duration: 50,
        translateY: [0, 16],
        opacity: [navLinkTransitionOpacity, 1],
        delay: anime.stagger(50),
      });

    // Set to reversed state initially
    tl.reverse();

    const handleClick = () => {
      tl.reverse();
      tl.play();

      if (checkBool.current.checked === true) {
        changeNavLinkDisplay();
        changeBodyOverflow("hidden");
        verticalMenuStyling();
      } else if (checkBool.current.checked === false) {
        changeBodyOverflow();
        if (isTabletOrMobile) {
          setTimeout(changeNavLinkDisplayNone, 500);
        }
      }
    };

    let menuCheckboxButton = document.getElementById("menu-checkbox");
    menuCheckboxButton.onclick = handleClick;
    return () => {
      menuCheckboxButton.removeEventListener("click", handleClick);
    };
  }, [isTabletOrMobile, isOpened]);

  useEffect(() => {
    // Prevents this error: typeError: Cannot read properties of null (reading 'click')
    const menuCheckboxButton = document.querySelector("#menu-checkbox");

    if (!isTabletOrMobile && checkBool.current.checked) {
      menuCheckboxButton.click();
      horizontalMenuStyling();
      changeNavLinkDisplay();
      changeBodyOverflow();
    } else if (isTabletOrMobile && !checkBool.current.checked) {
      changeNavLinkDisplayNone();
      changeBodyOverflow();
    } else if (!isTabletOrMobile && !checkBool.current.checked) {
      changeNavLinkDisplay();
    }

    const closeMenu = () => {
      if (isTabletOrMobile && checkBool.current.checked) {
        menuCheckboxButton.click();
        changeNavLinkDisplayNone();
      }
    };

    const navLinks = document.querySelectorAll(".nav-link");

    navLinks.forEach(function (navLink) {
      navLink.onclick = closeMenu;
    });

    mobileMenuMediaQuery(isTabletOrMobile, checkBool.current.checked);
    return () => {
      navLinks.forEach(function (navLink) {
        navLink.removeEventListener("click", closeMenu);
      });
    };
  }, [isTabletOrMobile]);

  return (
    <>
      <header id="header" className="header">
        <div className="navbar">
          <nav className="navbar-nav">
            {/* TODO: Make this into a loading icon with anime.js svg animation
              Make go down diagonally right, animate into a bigger and turn into
              a loading icon with a solid color background like Riccardo Zanutta
            and then back up to diagonally left */}
            <NavLink
              to="/"
              className="navbar-logo-link"
              onClick={(e) => loadDelayAndGo(e, "/")}
            >
              <img src={Logo} alt="Logo" className="navbar-logo" />
            </NavLink>
            <input
              type="checkbox"
              id="menu-checkbox"
              className="menu-checkbox"
              ref={checkBool}
            />
            <div className="hamburger hamburger--emphatic">
              <div className="hamburger-box">
                <div className="hamburger-inner"></div>
              </div>
            </div>

            <ul className="navbar-menu bold">
              <li id="aboutLink" className="nav-link">
                <NavLink
                  to="/about"
                  className="nav-link"
                  onClick={(e) => loadDelayAndGo(e, "/about")}
                >
                  About
                </NavLink>
              </li>
              <li>
                <HashLink
                  id="portfolioList"
                  smooth
                  to="/#portfolio"
                  className="nav-link"
                  onClick={(e) => {
                    if (window.location.pathname !== "/") {
                      loadDelayAndGo(e, "/#portfolio");
                    }
                  }}
                >
                  Portfolio
                </HashLink>
              </li>
              <li>
                <HandleModal buttonType={"link"} />
              </li>
            </ul>
          </nav>
          <div className="menu-block-one"></div>
          <div className="menu-block-two"></div>
        </div>
      </header>
      <ContactModal />
      <LoadingScreen />
    </>
  );
}
