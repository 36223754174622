import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "../apis/portfolioAPI";
import BackgroundImageOnLoad from "background-image-on-load";
import Experiments from "./Experiments/ExperimentList";
import HandleModal from "./Context/ModalContext";
import heroBackground from "../assets/img/HeroBackgroud.jpg";
import HomeBackgroundParticles from "./HomeBackgroundParticles";
import { LoadingScreenContext } from "./Context/LoadingScreenContext";
import ProjectCardList from "./Projects/ProjectCardList";

export default function Home() {
  const [heroInfo, setHeroInfo] = useState("");
  const { loadingStatus, bgImageLoadingStatus } =
    useContext(LoadingScreenContext);

  const [isLoading, setIsLoading] = loadingStatus;
  const [bgImageIsLoading, setBgImageIsLoading] = bgImageLoadingStatus;

  const fetchHomeHero = async () => {
    setBgImageIsLoading(true);
    setIsLoading(true);
    const response = await axios.get("/heroes/home-hero/");
    setHeroInfo(response.data);
    setIsLoading(false);
  };

  // Fixes first warning here: https://reactjs.org/blog/2020/02/26/react-v16.13.0.html
  // Refer to: https://stackoverflow.com/a/63659469/8156668
  const setBgImageLoadToFalse = () => {
    setTimeout(() => {
      setBgImageIsLoading(false);
    }, 0);
  };

  useEffect(() => {
    fetchHomeHero();
  }, []);

  return (
    <main>
      <div
        className="hero"
        style={{
          backgroundImage: `url(${heroBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
        }}
      >
        <BackgroundImageOnLoad
          src={heroBackground}
          onLoadBg={setBgImageLoadToFalse}
        />

        <HomeBackgroundParticles />

        <div className="container vh-full flex flex-middle flex-center column text-center noselect">
          <h1 className="hero-title mb-1 z-1">{heroInfo.name}</h1>
          <p className="hero-subtitle h3 z-1">{heroInfo.description}</p>
          <HandleModal buttonType={"cta"} cssClass={"z-1"} />
        </div>
      </div>

      <section id="portfolio" className="pb-5 pt-7">
        <h2 className="text-center">Latest Projects</h2>
      </section>

      <ProjectCardList />

      <section className="pb-5">
        <h2 className="text-center">Experiments</h2>
      </section>

      <Experiments />
    </main>
  );
}
