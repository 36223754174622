import React, { createContext, useContext } from "react";
import { useMediaQuery } from "react-responsive";

let isOpened;

export const ModalContext = createContext({
  isOpened: isOpened,
  setIsOpened: () => {},
});

export default function HandleModal({ buttonType, cssClass }) {
  const { setIsOpened } = useContext(ModalContext);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1200px)" });
  let modalOpenDelay;

  if (isTabletOrMobile) {
    modalOpenDelay = 500;
  } else {
    modalOpenDelay = 0;
  }

  return (
    <>
      {buttonType === "link" && (
        <button
          className="contact-link bold nav-link"
          onClick={() =>
            setTimeout(() => {
              setIsOpened(true);
            }, modalOpenDelay)
          }
        >
          Contact
        </button>
      )}
      {buttonType === "cta" && (
        <button className={`button cta-button ${cssClass}`} onClick={() => setIsOpened(true)}>
          Get Started
        </button>
      )}
      {buttonType === "close" && (
        <button
          type="button"
          className="modal-close noselect"
          onClick={() => setIsOpened(false)}
        >
          x
        </button>
      )}
    </>
  );
}
