import React, { useEffect, useContext } from "react";
import axios from "axios";
import BackgroundImageOnLoad from "background-image-on-load";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { LoadingScreenContext } from "../Context/LoadingScreenContext";

export default function Project() {
  const { loadingStatus, bgImageLoadingStatus } =
    useContext(LoadingScreenContext);

  const [isLoading, setIsLoading] = loadingStatus;
  const [bgImageIsLoading, setBgImageIsLoading] = bgImageLoadingStatus;

  /* Fetch the project info; this is just placeholder
     to trigger the loading screen */
  const fetchHomeHero = async () => {
    setBgImageIsLoading(true);
    setIsLoading(true);
    const response = await axios.get("/heroes/home-hero/");
    setIsLoading(false);
  };

  // Fixes first warning here: https://reactjs.org/blog/2020/02/26/react-v16.13.0.html
  // Refer to: https://stackoverflow.com/a/63659469/8156668
  const setBgImageLoadToFalse = () => {
    setTimeout(() => {
      setBgImageIsLoading(false);
      console.log("Project background loaded");
    }, 0);
  };

  useEffect(() => {
    fetchHomeHero();
  }, []);

  return (
    <main>
      {/* TODO:Create hero component with props to change styling and elements */}
      <section
        id="projectHero"
        className="project-hero"
        style={{
          backgroundImage: `url(https://images.pexels.com/photos/580679/pexels-photo-580679.jpeg)`,
        }}
      >
        <BackgroundImageOnLoad
          src={
            "https://images.pexels.com/photos/580679/pexels-photo-580679.jpeg"
          }
          onLoadBg={setBgImageLoadToFalse}
        />
        <div className="container vh-full flex flex-middle flex-center column text-white text-center relative">
          <h1 className="mb-1">Software Project</h1>
          <p className="h3">Software Project Blurb</p>
          <div className="hero-icon-list mt-4">
            <img
              src="https://via.placeholder.com/800x450.png?text=Technology+Used"
              className="hero-icon"
              alt="Loading..."
            />
            <img
              src="https://via.placeholder.com/800x450.png?text=Technology+Used"
              className="hero-icon"
              alt="Loading..."
            />
            <img
              src="https://via.placeholder.com/800x450.png?text=Technology+Used"
              className="hero-icon"
              alt="Loading..."
            />
            <img
              src="https://via.placeholder.com/800x450.png?text=Technology+Used"
              className="hero-icon"
              alt="Loading..."
            />
          </div>
          {/* TODO: Add scroll icon hover effects */}
          <HashLink
            smooth
            to="#projectInfoSection"
            className="project-scroll-link"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="50"
              height="70"
              viewBox="0 0 172 172"
              style={{ fill: "#000000" }}
            >
              <g style={{ mixBlendMode: "normal" }}>
                <path d="M0,172v-172h172v172z" fill="none"></path>
                <g fill="#ffffff">
                  <path d="M85.94625,3.38625c-1.89469,0.04031 -3.41312,1.59906 -3.38625,3.49375v148.79344l-28.58156,-27.42594c-0.65844,-0.65844 -1.55875,-1.02125 -2.48594,-1.00781c-1.3975,0.04031 -2.64719,0.91375 -3.14437,2.21719c-0.49719,1.30344 -0.16125,2.78156 0.87344,3.73562l36.77844,35.31375l36.77844,-35.31375c0.90031,-0.84656 1.27656,-2.10969 0.98094,-3.30562c-0.29563,-1.19594 -1.20938,-2.15 -2.39188,-2.48594c-1.19594,-0.34937 -2.4725,-0.02687 -3.34594,0.84656l-28.58156,27.42594v-148.79344c0.01344,-0.92719 -0.34937,-1.8275 -1.00781,-2.48594c-0.65844,-0.65844 -1.55875,-1.02125 -2.48594,-1.00781z"></path>
                </g>
              </g>
            </svg>
            <div className="scroll-link-text">Scroll</div>
          </HashLink>
        </div>
      </section>
      <section
        id="projectInfoSection"
        className="project-info-section pt-5 pb-8 text-center"
      >
        <div className="container">
          <h1 className="pt-2 pb-4">Project Description</h1>
          <div className="flex flex-center">
            <p className="project-description pb-8 mb-5 w-50">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="flex column flex-middle">
            <img
              src="https://via.placeholder.com/800x450.png?text=Cool+Web+App+Placeholder"
              className="first-gallery-img mb-9"
              alt="Loading..."
            />
            <img
              src="https://via.placeholder.com/800x450.png?text=Cool+Web+App+Placeholder"
              className="gallery-img mb-14"
              alt="Loading..."
            />
            <img
              src="https://via.placeholder.com/800x450.png?text=Cool+Web+App+Placeholder"
              className="gallery-img mb-14"
              alt="Loading..."
            />
            <img
              src="https://via.placeholder.com/800x450.png?text=Cool+Web+App+Placeholder"
              className="gallery-img mb-14"
              alt="Loading..."
            />
          </div>
        </div>
        <div className="project-bg-img"></div>
        <div className="mobile-images flex">
          <div className="mobile-view-image"></div>
          <div className="mobile-view-image"></div>
        </div>
        <div className="project-bg-img"></div>
      </section>
      <NavLink to="/project">
        <section className="next-project-img">
          <div className="container flex flex-middle flex-center column text-white text-center">
            <h1 className="mb-1">Next Software Project</h1>
            <p className="h3">Software Project Blurb</p>
            {/* Add row of technologies used as circle icons */}
            <svg
              className="next-project-arrow"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 172 172"
              style={{ fill: "#000000" }}
            >
              <g style={{ mixBlendMode: "normal" }}>
                <path d="M0,172v-172h172v172z" fill="none"></path>
                <g fill="#ffffff">
                  <path d="M130.84094,48.11969c-1.3975,-0.02687 -2.67406,0.80625 -3.23844,2.08281c-0.55094,1.29 -0.26875,2.78156 0.71219,3.77594l27.43938,28.58156h-148.79344c-1.23625,-0.01344 -2.39188,0.63156 -3.01,1.70656c-0.63156,1.075 -0.63156,2.39187 0,3.46687c0.61812,1.075 1.77375,1.72 3.01,1.70656h148.79344l-27.43938,28.58156c-1.31687,1.37062 -1.26312,3.5475 0.1075,4.86437c1.37063,1.31688 3.5475,1.27656 4.86438,-0.1075l35.31375,-36.77844l-35.31375,-36.77844c-0.63156,-0.68531 -1.51844,-1.075 -2.44563,-1.10188z"></path>
                </g>
              </g>
            </svg>
          </div>
        </section>
      </NavLink>
    </main>
  );
}
