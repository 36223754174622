import React from 'react'

export default function ValueCard({ valueCard }) {
    return (
        <>
          <div className="value-icon">
            <img src={valueCard.iconImage} alt="Loading..." className={`pb-1 ${valueCard.cssClasses}`} />
            <h3 className="pb-1">{valueCard.title}</h3>
            <p>{valueCard.description}</p>
          </div>
        </>
    )
}
