import { get } from "animejs";
import axios from "axios";

// Generate token with the command python manage.py drf_create_token kenny
// (In .env file)

// Refer to https://stackoverflow.com/questions/40988238/sending-the-bearer-token-with-axios
// Change Bearer to Token
// This works for authenticating:
// const instance = axios.create({
//   baseURL: "http://127.0.0.1:8000/api",
//   headers: { Authorization: `Token ${AUTH_TOKEN}` },
// });

// export default instance;

// Make sure you Disable HTTPS Everywhere on Localhost for live API URL
// Refer to: https://stackoverflow.com/questions/53087341/the-access-control-allow-origin-header-has-a-value-http-localhost4200-tha
// This also works for authenticating:
// axios.defaults.baseURL = "http://127.0.0.1:8000/api"
// axios.defaults.withCredentials = true;

axios.defaults.baseURL = 
"https://kennyleppingapi.com/api"
//   "http://127.0.0.1:8000/api";

axios.defaults.headers = { 'Authorization': `Token ${process.env.REACT_APP_API_KEY}`,}

// axios.defaults.headers.common[
//   "Authorization"
// ] = `Token ${process.env.REACT_APP_API_KEY}`;

//Alternative way to write axios.get
// const urls = {
//   'projects': "/projects/",
//   'menus': '/something-else/menus/'
// };

// const api = {
//   async get(item) {
//     url = urls[item];
//     return (await axios.get(url)).data;
//   },
//   async getSpecific(item, id) {
//     url = urls[item] 
//   }
// };

// Then export the API
// export default api


// Use the custom API object that you build
// async function getProjects() { 
//   const projects = await api.get('projects') 
// }


// VS the other way that's more UGLEH!
// let myData = null
// axios.get('/projects/').then(response => { myData = response.data })


export default axios;