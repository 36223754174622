import React from "react";

export default function Experiment({ experiment }) {
  console.log(experiment.image);
  return (
    <a href={experiment.link} className="experiment">
      <span
        className="experiment-image"
        style={{
          backgroundImage: `url(${experiment.image})`,
          backgroundSize: "cover",
        }}
        alt="Experiment"
      />
      <div className="experiment-text">
        <div className="experiment-title">{experiment.title}</div>
        <div className="experiment-blurb">{experiment.blurb}</div>
      </div>
    </a>
  );
}
