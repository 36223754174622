import React from "react";
import Experiment from "./Experiment";

export default function Experiments() {
  let experiments = [
    {
      link: "https://codepen.io/kennylepping/pen/QWvjBOq",
      image: "./../../assets/img/menu-experiment.jpg",
      title: "Mobile Menu",
      blurb: "A menu using the anime.js animation library",
    },
    {
      link: "#",
      image: "",
      title: "My Experiment 2",
      blurb: "May the force be with you",
    },
    {
      link: "#",
      image: "",
      title: "My Experiment 3",
      blurb: "Cool experiment",
    },
    {
      link: "#",
      image: "",
      title: "My Experiment 4",
      blurb: "This is the way",
    },
  ];
  return (
    <>
      <section className="experiment-list">
        {experiments.map((experiment, index) => (
          <Experiment key={index} experiment={experiment} />
        ))}
      </section>
    </>
  );
}
