import React from "react";
import ValueCard from "./ValueCard";
import appleImage from "../../assets/valueIcons/icons8-red-apple-96.png";
import creationImage from "../../assets/valueIcons/icons8-web-development-96.png";
import freedomImage from "../../assets/valueIcons/icons8-freedom-96.png";
import growthImage from "../../assets/valueIcons/icons8-bonsai-96.png";
import heartImage from "../../assets/valueIcons/icons8-heart-96.png";
import integrityImage from "../../assets/valueIcons/icons8-star-96.png";
import worldImpactImage from "../../assets/valueIcons/icons8-earth-58.png";

export default function ValueList() {
  let valuesList = [
    {
      iconImage: growthImage,
      title: "Growth",
      description:
        "I actively expand in my knowledge and abilities.",
      cssClasses: "",
    },
    {
      iconImage: worldImpactImage,
      title: "World Impact",
      description: "I work to make a massive positive impact on the world.",
      cssClasses: "world-impact-icon",
    },
    {
      iconImage: freedomImage,
      title: "Freedom",
      description: "I express myself authentically and aspire to self-mastery.",
      cssClasses: "",
    },
    {
      iconImage: appleImage,
      title: "Health",
      description: "I take care of my body & mind to perform at peak levels.",
      cssClasses: "",
    },
    {
      iconImage: heartImage,
      title: "Love",
      description: "I put my heart & soul into my work and my relationships.",
      cssClasses: "",
    },
    {
      iconImage: integrityImage,
      title: "Integrity",
      description:
        "I live & communicate with truth, honesty, and transparency.",
      cssClasses: "",
    },
    {
      iconImage: creationImage,
      title: "Creation",
      description: "The ability to do anything, but only whenever I want.",
      cssClasses: "",
    },
  ];

  return (
    <>
      <h1 className="text-center pb-4">Values</h1>
      <div className="flex flex-center value-icons mx-auto">
        {valuesList.map((valueCard, index) => (
          <ValueCard key={index} valueCard={valueCard} />
        ))}
      </div>
    </>
  );
}
