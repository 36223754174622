import React, { createContext, useContext, useState, useEffect } from "react";
import anime from "animejs";

export const LoadingScreenContext = createContext();

export const LoadingScreenContextProvider = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [bgImageIsLoading, setBgImageIsLoading] = useState(false);

  return (
    <LoadingScreenContext.Provider
      value={{
        loadingStatus: [isLoading, setIsLoading],
        bgImageLoadingStatus: [bgImageIsLoading, setBgImageIsLoading],
      }}
    >
      {props.children}
    </LoadingScreenContext.Provider>
  );
};

export default function LoadingScreen() {
  const { loadingStatus, bgImageLoadingStatus } =
    useContext(LoadingScreenContext);

  const [isLoading, setIsLoading] = loadingStatus;
  const [bgImageIsLoading, setBgImageIsLoading] = bgImageLoadingStatus;
  const [onFirstLoad, setOnFirstLoad] = useState();

  function showLoadingScreen() {
    document.querySelector(".loading-background-front").style.display = "block";
    anime({
      targets: ".loading-background-front",
      duration: 300,
      easing: "linear",
      scale: 1,
      borderRadius: ["100vh", 0],
    });
  }

  function playLoadingAnimation() {
    anime({
      targets: ".loading-shape",
      translateY: [
        {
          value: 200,
          duration: 500,
        },
        {
          value: 0,
          duration: 800,
        },
      ],
      loop: true,
      rotate: {
        value: "1turn",
        duration: 900,
      },
      borderRadius: [50, 0],
      direction: "alternate",
      easing: "easeInOutQuad",
      delay: function () {
        return anime.random(0, 1000);
      },
      elasticity: 200,
    });
  }

  function shrinkLoadingScreen() {
    anime({
      targets: ".loading-background-front",
      duration: 300,
      easing: "linear",
      scale: 0,
      borderRadius: "100vh",
    });
    setTimeout(() => {
      document.querySelector(".loading-background-front").style.display =
        "none";
    }, 330);
  }

  useEffect(() => {
    setOnFirstLoad(true);
    document.querySelector(".loading-background-front").style.display = "block";
    document.querySelector(".loading-background-front").style.transform =
      "scale(1)";
    playLoadingAnimation();
    setOnFirstLoad(false);
  }, []);

  useEffect(() => {
    if (isLoading && bgImageIsLoading) {
      if (onFirstLoad === false) {
        showLoadingScreen();
      }
      playLoadingAnimation();
    }
    if (!isLoading && !bgImageIsLoading) {
      shrinkLoadingScreen();
    }
  }, [isLoading, bgImageIsLoading]);

  return (
    <div className="loading loading-background-front">
      <div className="loader-wrapper">
        <div className="loading-shapes">
          <span className="loading-shape one" />
          <span className="loading-shape two" />
          <span className="loading-shape three" />
          <span className="loading-shape four" />
        </div>
      </div>
    </div>
  );
}
