import anime from "animejs";

export function changeNavLinkDisplay(value = "flex") {
  document.querySelector(".navbar-menu").style.display = value;
}

export function changeNavLinkDisplayNone() {
  document.querySelector(".navbar-menu").style.display = "none";
}

export function horizontalMenuStyling() {
  var horizontalNavLinks = document.querySelector(".navbar-menu").style;
  horizontalNavLinks.display = "flex";
  horizontalNavLinks.justifyContent = "space-between";
  horizontalNavLinks.alignItems = "center";
  horizontalNavLinks.listStyleType = "none";
  horizontalNavLinks.width = "34%";
  horizontalNavLinks.flexFlow = "row";
  horizontalNavLinks.position = "static";
}

export function verticalMenuStyling() {
  var navLinks = document.querySelector(".navbar-menu").style;
  navLinks.alignItems = "center";
  navLinks.flexFlow = "column";
  navLinks.justifyContent = "center";
  navLinks.inset = 0;
  navLinks.position = "fixed";
  navLinks.width = "100%";
  navLinks.zIndex = 6;
}

export function changeBodyOverflow(value = "auto") {
  document.querySelector("body").style.overflow = value;
}

export function changeNavLinkOpacity(value = 1) {
  anime({
    targets: ".navbar-menu li",
    opacity: value,
    duration: 500,
  });
}

export function mobileMenuMediaQuery(isTabletOrMobile, menuIsChecked) {
  if (!isTabletOrMobile && !menuIsChecked) {
    changeNavLinkDisplay();
    changeNavLinkOpacity();
    horizontalMenuStyling();
  }
}

export function fadeOut(element) {
  anime({
    duration: 500,
    targets: element,
    easing: "linear",
    opacity: 0,
  });
}

export function fadeIn(element) {
  anime({
    duration: 500,
    targets: element,
    opacity: 1,
  });
}

// TODO: Make this into a utils directory and import all files into one file like with CSS
// Refer to this: https://stackoverflow.com/questions/46173455/react-structure-for-own-utils-files
