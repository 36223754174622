import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ModalContext } from "./components/Context/ModalContext";
import { LoadingScreenContextProvider } from "./components/Context/LoadingScreenContext";
import About from "./components/About";
import Footer from "./components/Footer";
import Home from "./components/Home";
import NavBar from "./components/NavBar";
import Project from "./components/Projects/Project";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  const [isOpened, setIsOpened] = useState(false);
  const modalStatus = { isOpened, setIsOpened };

  return (
    <>
      <BrowserRouter>
        <ModalContext.Provider value={modalStatus}>
          <LoadingScreenContextProvider>
            <NavBar />
            <ScrollToTop />
            <Switch>
              <Route component={Home} path="/" exact />
            </Switch>
            <Switch>
              <Route component={Project} path="/project" exact />
            </Switch>
            <Switch>
              <Route component={About} path="/about" exact />
            </Switch>
            <Footer />
          </LoadingScreenContextProvider>
        </ModalContext.Provider>
      </BrowserRouter>
    </>
  );
}

export default App;
