import React from "react";
import Particles from "react-particles-js";

export default function HomeBackgroundParticles() {
    // Find way to make this responsive
  return (
    <> 
      <Particles
        style={{
          position: "absolute",
        }}
        height="100%"
        width="100%"
        params={{
          particles: {
            number: {
              value: 27, // Was initially at 7
              density: {
                enable: true,
                value_area: 800,
              },
            },
            color: {
              value: "#000143",
            //   value: "#00306E",
            },

              shape: {
                type: "edge",
                stroke: {
                  width: 0,
                  color: "#000"
                },
                polygon: {
                },
                image: {
                  src: "/K-logo.svg",
                  width: 100,
                  height: 100,
                },
              },
              opacity: {
                value: 0.6,
                random: true,
                anim: {
                  enable: false,
                  speed: 1,
                  opacity_min: 0.1,
                  sync: false,
                },
              },
              size: {
                value: 160,
                random: false,
                anim: {
                  enable: true,
                  speed: 10,
                  size_min: 40,
                  sync: false,
                },
              },
              line_linked: {
                enable: false,
                distance: 200,
                color: "#ffffff",
                opacity: 1,
                width: 2,
              },
              move: {
                enable: true,
                speed: 4,
                direction: "top-right",
                random: false,
                straight: false,
                out_mode: "out",
                bounce: false,
                attract: {
                  enable: false,
                  rotateX: 600,
                  rotateY: 1200,
                },
              },
            },
            interactivity: {
              detect_on: "canvas",
              events: {
                onhover: {
                  enable: true,
                  mode: "repulse",
                },
                onclick: {
                  enable: true,
                  mode: "push",
                },
                resize: true,
              },
              modes: {
                grab: {
                  distance: 400,
                  line_linked: {
                    opacity: 1,
                  },
                },
                bubble: {
                  distance: 400,
                  size: 40,
                  duration: 2,
                  opacity: 8,
                  speed: 3,
                },
                repulse: {
                  distance: 200,
                  duration: 0.4,
                },
                push: {
                  particles_nb: 4,
                },
                remove: {
                  particles_nb: 2,
                },
            },
          },
          retina_detect: true,
        }}
      />
    </>
  );
}
