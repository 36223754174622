import React, { useContext } from "react";
import Logo from "../assets/svg/K-logo.svg";
import { LoadingScreenContext } from "./Context/LoadingScreenContext";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router";

export default function Footer() {
  const { loadingStatus, bgImageLoadingStatus } =
    useContext(LoadingScreenContext);

  const [isLoading, setIsLoading] = loadingStatus;
  const [bgImageIsLoading, setBgImageIsLoading] = bgImageLoadingStatus;

  const history = useHistory();

  function loadDelayAndGo(e, path) {
    e.preventDefault();
    let delay = 0;
    if (window.location.pathname !== path) {
      delay = 500;
      setIsLoading(true);
      setBgImageIsLoading(true);
    }
    setTimeout(() => {
      history.push(path);
      window.scrollTo(0, 0);
    }, delay);
  }

  return (
    <footer className="footer py-10 bold">
      <NavLink
        to="/"
        onClick={(e) => {
          loadDelayAndGo(e, "/");
        }}
      >
        <img src={Logo} alt="Logo" className="navbar-logo footer-logo pb-3" />
      </NavLink>
      <ul className="footer-nav pb-2">
        <li className="footer-link">
          <a href="https://www.linkedin.com/in/kenneth-lepping">LinkedIn</a>
        </li>
        <li className="footer-link">
          <a href="https://github.com/KennyLepping">Github</a>
        </li>
        <li className="footer-link">
          <a href="https://codepen.io/kennylepping">Codepen</a>
        </li>
      </ul>
      <p className="footer-copyright bold">© Kenneth Lepping</p>
    </footer>
  );
}
