import React, { useState, useEffect } from "react";
import axios from "axios";
import ProjectCard from "./ProjectCard";
import apgImage from "../../assets/img/AP&G-1.jpg";
import drumthesiaAsset from "../../assets/img/DrumthesiaExampleOne_159.jpg";
import drumthesia from "../../assets/img/Galaxy.jpg";

export default function ProjectList() {
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    const fetchProjects = async () => {
      const response = await axios.get("/projects/");
      setProjects(response.data);

      console.log(response.data);
    };

    fetchProjects();
  }, []);

  // Or add order field or get pk, id field from DRF, etc.
  // TODO: To sort projects, refer to: https://stackoverflow.com/questions/44535972/react-js-sort-by-ascending-and-descending

  var projectList = [
    {
      title: "Cool Web App",
      blurb: "React and Machine Learning probably",
      backgroundImagePath: `url(${drumthesia})`,
      imagePath:
        "https://via.placeholder.com/800x450.png?text=Cool+Web+App+Placeholder",
    },
    {
      title: "Websites",
      blurb: "American Pond and Gardens and Underground Only",
      backgroundImagePath: `url(${drumthesia})`,
      imagePath: apgImage,
    },
    {
      title: "Cool Web App",
      blurb: "React and Machine Learning probably",
      backgroundImagePath: `url(${drumthesia})`,
      imagePath: drumthesiaAsset,
    },
  ];
  return (
    <>
      {projects.map((project, index) => (
        <ProjectCard key={index} project={project} />
      ))}
    </>
  );
}
