import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";
import anime from "animejs";
import axios from "../apis/portfolioAPI";
import emailjs from "emailjs-com";
import HandleModal, { ModalContext } from "./Context/ModalContext";
import { fadeIn, fadeOut } from "../anime_js/main";

export default function ContactModal() {
  const { isOpened } = useContext(ModalContext);
  const triggerAnimationRef = useRef();

  function ConvertWordToSpan({ word }) {
    return (
      <span>
        {word.split("").map((letter, letterIndex) => (
          <span key={letterIndex} className="single-letter">
            {letter}
          </span>
        ))}
      </span>
    );
  }

  function TextToSpan({ children }) {
    return (
      <div>
        {children.split(" ").map((word, wordIndex) => (
          <Fragment key={wordIndex}>
            <ConvertWordToSpan key={wordIndex} word={word} />
            <span> </span>
          </Fragment>
        ))}
      </div>
    );
  }

  useEffect(() => {
    let modal = document.querySelector(".modal");
    let contactFormOutline = document.querySelector(".contact-form-outline");
    let forFadeOut = 0;

    // Makes it so the modal fades out when closed
    if (!isOpened) {
      forFadeOut = 1;
    }

    const modalDisplayNone = () => {
      modal.style.display = "none";
    };

    const modalTL = anime.timeline({
      targets: modal,
      easing: "easeInQuad",
      autoplay: false,
      duration: 500,
    });
    modalTL
      .add({
        targets: ".line-draw-up",
        top: ["100%", 0],
        duration: 100,
      })
      .add({
        targets: ".line-draw-down",
        bottom: ["100%", 0],
        duration: 100,
      })
      .add({
        targets: ".line-draw-right",
        right: ["100%", 0],
        duration: 100,
      })
      .add({
        targets: ".line-draw-left",
        left: ["100%", 0],
        duration: 100,
      })
      .add({
        targets: ".contact-form",
        easing: "linear",
        opacity: [forFadeOut, 1],
      });

    triggerAnimationRef.current = anime
      .timeline({
        autoplay: false,
      })
      .add({
        targets: ".contact-form-success",
        top: 0,
      })
      .add(
        {
          targets: ".svg-check",
          opacity: [0, 1],
          scale: [0, 1],
        },
        "-=900"
      )
      .add(
        {
          targets: ".single-letter",
          translateY: [100, 0],
          opacity: [0, 1],
          easing: "easeOutExpo",
          duration: 700,
          delay: function (el, i) {
            return 300 + 30 * i;
          },
        },
        "-=900"
      )
      .add(
        {
          targets: ".success-message",
          opacity: [0, 1],
          scaleX: [0, 1],
        },
        "-=300"
      )
      .add(
        {
          targets: ".contact-form-success",
          scale: 0,
          borderRadius: "100vh",
          easing: "linear",
          duration: 200,
        },
        "+=5000"
      )
      .add({
        begin: function () {
          document.querySelector(".contact-form-success").style.display =
            "none";
        },
      });

    if (isOpened) {
      contactFormOutline.style.display = "block";
      modal.style.display = "flex";
      fadeIn(modal);
      modalTL.restart();
    } else {
      fadeOut(modal);
      contactFormOutline.style.display = "none";
      setTimeout(modalDisplayNone, 600);
    }
  }, [isOpened]);

  return (
    //   {/* TODO: Make text input transparent with animated on hover bottom border */}
    <div className="modal flex flex-center flex-middle">
      <div className="modal-width mx-auto overflow-hidden">
        <FormComponent
          animateCallback={() => {
            !!triggerAnimationRef.current &&
              triggerAnimationRef.current.restart();
          }}
        />
        <div className="contact-form-outline">
          <span className="line-draw-up" />
          <span className="line-draw-right" />
          <span className="line-draw-down" />
          <span className="line-draw-left" />
        </div>
        <div className="contact-form-success">
          <div className="flex flex-center flex-middle column h-100">
            <svg
              className="svg-check mb-3"
              viewBox="0 0 512 512"
              width="250"
              title="check-circle"
            >
              <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" />
            </svg>
            <div className="form-success-message">
              <h2 className="mb-1">
                <div className="relative mb-2">
                  <TextToSpan>Thank you for contacting me!</TextToSpan>
                </div>
              </h2>
              <p className="success-message text-center">
                Your message has been successfully sent. I will contact you very
                soon!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function FormComponent({ animateCallback }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    // Add loading animation here

    e.preventDefault();
    axios
      .post("/contact-messages/", {
        name: name,
        email: email,
        message: message,
      })
      .then(function (response) {
        console.log(`Axios response: ${response.status}`);

        // Show success message:
        document.querySelector(".contact-form-success").style.display = "block";
        animateCallback();

        if (response.status === 201) {
        //   Clear the form when the message is saved
            const resetForm = () => {
              setName("");
              setEmail("");
              setMessage("");
              console.log("Form cleared");
            };
            setTimeout(resetForm, 900);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    let templateParams = {
      name: name,
      email: email,
      message: message,
    };

    // Sending emails with emailjs
    emailjs
      .send(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAIL_USER_ID
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  };

  // TODO: Set up Amazon SES on AWS, in Django, and with API gateway

  return (
    <form
      id="contactForm"
      onSubmit={handleSubmit}
      className="contact-form text-white z-1"
    >
      <div className="form-field mb-2">
        <h2 className="mb-2">Contact Me</h2>
        <label htmlFor="name">Name</label>
        <input
          type="text"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div className="form-field mb-2">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div className="form-field mb-2">
        <label htmlFor="message">Message</label>
        <textarea
          type="message"
          name="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
      </div>
      <button
        type="submit"
        value="Submit"
        id="submitBtn"
        className="submit-btn"
      >
        Submit
      </button>
      <HandleModal buttonType={"close"} />
      <div className="success-block-one"></div>
      <div className="success-block-two"></div>
    </form>
  );
}
