import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router";
import { LoadingScreenContext } from "../Context/LoadingScreenContext";

export default function ProjectCard({ project }) {
  const { loadingStatus, bgImageLoadingStatus } =
    useContext(LoadingScreenContext);

  const [isLoading, setIsLoading] = loadingStatus;
  const [bgImageIsLoading, setBgImageIsLoading] = bgImageLoadingStatus;

  const history = useHistory();

  function loadDelayAndGo(e, path) {
    e.preventDefault();
    if (window.location.pathname !== path) {
      setIsLoading(true);
      setBgImageIsLoading(true);
    }
    setTimeout(() => {
      history.push(path);
      window.scrollTo(0, 0);
    }, 500);
  }

  return (
    /* TODO: Go through this for dynamic links
     https://learnwithparam.com/blog/dynamic-pages-in-react-router/
     For delaying when react-router-dom switches to next page:
    https://stackoverflow.com/questions/49840197/how-can-i-set-delay-function-in-react-routing
    https://stackoverflow.com/questions/51803771/how-to-delay-the-redirect-of-a-react-router-link-component-for-1-second
    https://www.google.com/search?q=react-router-dom+delay+switching+to+page&oq=react-router-dom+delay+switching+to+page&aqs=chrome..69i57j33i160l2.14416j0j4&sourceid=chrome&ie=UTF-8 
      */
    <section className="project-card-wrapper">
      <div className="container flex flex-center mx-auto relative">
        <NavLink
          to="/project"
          className="nav-link-wrapper mb-12 project-card"
          onClick={(e) => loadDelayAndGo(e, "/project")}
        >
          <div
            className="project-image"
            style={{
              //   backgroundImage: project.backgroundImagePath,
              backgroundImage: `url(${project.image})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="container relative z-1 project-info">
              <h2>{project.name}</h2>
              <p>{project.blurb}</p>
              <button className="view-btn my-1">View Project</button>
            </div>
            <div className="container flex flex-end flex-middle drumthesia-container">
              <img
                src={project.secondary_image}
                className={`asset absolute z-1 mb-5 ${
                  project.has_logo_image ? "logo-asset-width" : ""
                }`}
                alt="Loading..."
              />
            </div>
            <div className="project-image-mask"></div>
          </div>
        </NavLink>
      </div>
    </section>
  );
}
